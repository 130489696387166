@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;1,900&display=swap');

$primary: #000;
$font-size-base: 1rem;
$font-size-xl: 1.3rem;
$font-size-lg: 1.1rem;
$font-size-sm: 0.8rem;
$font-size-xs: 0.6rem;
$white: #fff;
$gray-300: #cdcdcd;
$default: #bcbcbc;
$green: green;
$info: #444;
$gray-800: gray;

body {
    color: $primary;
    font-family: 'Roboto', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.font-size-base{
    font-size: $font-size-base;
}
.font-size-xl{
    font-size: $font-size-xl;
}
.font-size-lg{
    font-size: $font-size-lg;
}
.font-size-sm{
    font-size: $font-size-sm;
}
.font-size-xs{
    font-size: $font-size-xs;
}

.btn {
    padding: 0.2rem 1.25rem;

    .fa, .fas {
        width: 12px;
        height: 15px;
    }
}
.btn-group-sm>.btn, .btn-sm {
    padding: .25rem .5rem;
    line-height: 1.5;
    border-radius: .25rem;
}

section.main {
    min-height: calc(100vh - 22px);
    padding-top: 150px;
}
.presentation-page section.main {
    padding-bottom: 0 !important;
}
.nav-link {
    color: $primary;
}
.card.no-transition:hover, .card-plain.no-transition:hover {
    transform: none !important;

}
.card-plain.no-transition:hover {
    box-shadow: none;
    
}
.headroom--not-top {
    .nav-link {
        color: $white;
    }
    color: #dee2e6;
}
.footer{
    .copyright {
        line-height: 3.1;
        padding: 5px 0;
        width: 100% !important;
        a {
            color: rgba(255, 255, 255, 0.7) !important;
            text-transform: capitalize;
            text-decoration: none;
            transition: color .1s;
            font-weight: 500;
            font-size: 0.875rem;
            display: block;
            padding: 0.25rem 0.75rem;
            &:hover {
                color: $white !important;
            }
        }

    }
}
.btn.btn-icon-only.btn-md .btn-inner--icon i {
    top: -10px;
    left: -10px;
    font-size: 20px;
    position: relative;
}
// .btn-google {
//     padding: 1.29rem;
//     border-color: $gray-300;
//     img {
//         height: 20px;
//         width: 20px;
//         left: -10px;
//         top: -10px;
//         position: relative;
//     }
// }

.headroom--top {
    .title {
        color: $primary;
    }
}

.headroom--not-top {
    .title {
        color: $gray-300;    
    }
}

.card {
    transition: all 300ms ease-in;
}
.card:hover {
    box-shadow: 0 14px 35px rgba(50, 50, 93, 0.2), 0 10px 35px rgba(0, 0, 0, 0.15);
    transform: translateY(-3px);
}

.cursor-pointer {
    cursor: pointer;
}
.menu-selectors {
    .dropdown-toggle::after {
        float: right;
        margin-top: 8px;
    }
}

.joborderborder:last-child{
    border-bottom-left-radius:  .28571429rem .28571429rem;
    border-bottom-right-radius: .28571429rem .28571429rem;
}
.joborderborder:nth-child(2){
    border-top-left-radius:  .28571429rem .28571429rem;
    border-top-right-radius: .28571429rem .28571429rem;
}
.joborderborder{
    border: 1px solid #cad1d7;
}
.joborderborder:not(:nth-child(2)){
    border-top: none;
}
.form-control:not(textarea) {
    height: calc(0.5em + 1.25rem + 2px);
}
.input-group-text {
    padding: 0.25rem 0.75rem;
}
.downshift {
    position: relative;
    .form-control[readonly] {
        background-color: #fff;
    }
    .input-group.is-open {
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        .form-control {
            border-bottom-left-radius: 0 !important;
        }
        .input-group-text {
            border-bottom-right-radius: 0 !important;
        }
    }
    ul {
        padding: 0;
        margin-top: 0;
        position: absolute;
        background-color: white;
        width: 100%;
        max-height: 20rem;
        overflow-y: auto;
        overflow-x: hidden;
        outline: 0;
        transition: opacity .1s ease;
        border-radius: 0 0 .28571429rem .28571429rem;
        box-shadow: none;        
        border: none;
        z-index: 9999;
        &.is-open {
            border-color: #cad1d7;
            border-top-width: 0;
            border-right-width: 1px;
            border-bottom-width: 1px;
            border-left-width: 1px;
            border-style: solid;
        }
        li {
            position: relative;
            cursor: pointer;
            display: block;
            border: none;
            height: auto;
            text-align: left;
            border-top: none;
            line-height: 1em;
            color: #8898aa;
            font-size: .875rem;
            text-transform: none;
            font-weight: 400;
            box-shadow: none;
            padding: .8rem 1.1rem;
            white-space: normal;
            word-wrap: normal;
            &.is-active {
                background: rgba(0,0,0,.1);
            }
            &.is-selected {
                font-weight: 700;
            }
        }
    }
    &.multiselect {
        .selected-items {
            display: flex;
            border: 1px solid #cad1d7;
            flex-grow: 1;
            flex-basis: 0;
            border-radius: 0.25rem;
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
            border-right: none;
            padding: .414rem .75rem;
            font-weight: 400;
            line-height: 1.5;
            color: #8898aa;
            font-size: 0.875rem;
            flex-wrap: wrap;
            .item-placeholder {
                padding: .275rem .75rem .15rem .85rem;
            }
            .item-text {
                padding: .15rem .75rem .15rem .85rem;
                white-space: nowrap;
                transition: all 300ms ease-in-out;
                border: 1px solid #eeeeee;
                border-radius: 15px;
                &:focus {
                    outline: none;
                }
                &:not(:first-child) {
                    margin-left: .5rem;
                }
                &:hover {
                    background-color: #eeeeee;
                }
                .item-remove {
                    margin-left: .5rem;
                    cursor: pointer;
                    transition: all 200ms ease-in-out;
                    &:hover {
                        color: #000;
                    }
                }
            }
            
        }
        .input-group-text {
            border-left: none;
        }
    }
}

.form-group.error {
    & .input-group {
        & > input {
            border-color: red;
        }
        & > div[type=button] .input-group-text {
            border-color: red;
        }
    }
}

// $input-btn-padding-y-sm:      .25rem !default;
// $input-btn-padding-x-sm:      .5rem !default;
// $input-btn-font-size-sm:      $font-size-sm !default;
// $input-btn-line-height-sm:    $line-height-sm !default;

// $input-btn-padding-y-lg:      .5rem !default;
// $input-btn-padding-x-lg:      1rem !default;
// $input-btn-font-size-lg:      $font-size-lg !default;
// $input-btn-line-height-lg:    $line-height-lg !default;

// $border-radius:               .25rem !default;
// $border-radius-lg:            .3rem !default;
// $border-radius-sm:            .2rem !default;

// $font-size-base:              1rem !default; // Assumes the browser default, typically `16px`
// $font-size-lg:                $font-size-base * 1.25 !default;
// $font-size-sm:                $font-size-base * .875 !default;

// $line-height-lg:              1.5 !default;
// $line-height-sm:              1.5 !default;
//0.219rem 0.553rem
// .btn-md {
//     @include button-size(0.219rem, .584rem, $font-size-base * 1.0625, 1.5, .25rem);
// }
.btn-md {
    font-size: $font-size-base;
  
    &.btn-icon-only {
      width: 43px;
      height: 43px;
    }
  
    .btn-inner--icon {
      font-size: 0.75rem;
    }
  }

  .drop-target {
      padding: 1rem;
  }
  .table-header-rotated {
    //   width: auto !important;
    &.droppable {
        th {
            padding: 0 !important;
        }
    }
    th, td {
        border-left: 0.0625rem solid #dee2e6;
        border-right: 0.0625rem solid #dee2e6;
    }
    th.rotate {
        height: 140px;
        white-space: nowrap;
        padding: 0 !important;
        border-left: 0.0625rem solid #dee2e6;
        border-right: 0.0625rem solid #dee2e6;
        width: 50px;
    }
    th.rotate.placeholder {
        width: unset;
    }
    th.rotate > div {
        transform:
            translate(0px, 0px)
            rotate(270deg);
        width: 30px;
    }
    
    th.rotate > div > span {
        padding: 10px;
    }
  }
// div.table { 
//     display: table; 
//     & div.tr { display: table-row; }
//     & div.thead { display: table-header-group; }
//     & div.tbody { display: table-row-group; }
//     & div.tfoot { display: table-footer-group; }
//     & div.col { display: table-column; }
//     & div.colgroup { display: table-column-group; }
//     & div.td, div.th { display: table-cell; }
//     & div.caption { display: table-caption; }
// }
  
.table {
    .td, .th {
        white-space: pre-wrap;
        overflow-wrap: break-word;
    }
    & th, & td {
        overflow: hidden;

    }
    &.flex {
        
            .tbody {
                display: block;
                height: calc(100vh - 400px);
                overflow-y: scroll;
                overflow-x: hidden;

                .td + *:not(.expander) {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }

          
    }

    &.scrollable {
        tbody {
            display: block;
            height: calc(100vh - 400px);
            overflow-y: scroll;
        }
     
        thead, tbody tr {
            display: table;
            width: 100%;
            table-layout: fixed;/* even columns width , fix width of table too*/
        }
        thead tr th:last-child {
            padding-right: 2rem;
        }
    }
}




.select-filter {
	display: block;
	color: #8898aa;
	padding: .6em 1.4em .5em .8em;
	width: 100%;
	max-width: 100%; /* useful when width is set to anything other than 100% */
	box-sizing: border-box;
	margin: 0;
	border: 1px solid #cad1d7;
	box-shadow: none;
	border-radius: .25em;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	background-color: #fff;
    font-size: 1.137em;
	/* note: bg image below uses 2 urls. The first is an svg data uri for the arrow icon, and the second is the gradient. 
		for the icon, if you want to change the color, be sure to use `%23` instead of `#`, since it's a url. You can also swap in a different svg icon or an external image reference
		
	*/
	background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='chevron-down' class='svg-inline--fa fa-chevron-down fa-w-14' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath fill='%23cad1d7' d='M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z'%3E%3C/path%3E%3C/svg%3E");
	background-repeat: no-repeat, repeat;
	/* arrow icon position (1em from the right, 50% vertical) , then gradient position*/
	background-position: right .7em top 50%, 0 0;
	/* icon size, then gradient */
	background-size: .65em auto, 100%;
}
/* Hide arrow icon in IE browsers */
.select-filter::-ms-expand {
	display: none;
}
/* Hover style */
.select-filter:hover {

}
/* Focus style */
.select-filter:focus {
	border-color: #4b4b4a;
	/* It'd be nice to use -webkit-focus-ring-color here but it doesn't work on box-shadow */
	box-shadow: none;
	color: #8898aa; 
	outline: none;
}

/* Set options to normal weight */
.select-filter option {
	font-weight:normal;
    font-size: 1.137em;
}
.input-filter {
    width: 100%;
	color: #8898aa;
    font-size: 1.137em;
    border: 1px solid #cad1d7;
    padding: .6em .4em .5em .8em;
    border-radius: .25em;
    &:focus {
        border-color: #4b4b4a;
        box-shadow: none;
        color: #8898aa;
        outline: none;
        & + .input-group-append .input-group-text {
            border-color: #4b4b4a;
            box-shadow: none;
            color: #8898aa;
            outline: none;
        }
    }
}

.td-action {
    width: 100px;
}
.th-action {
    width: 117px;
}
.td-action-150 {
    width: 150px;
}
.th-action-150 {
    width: 167px;
}
.td-action-250 {
    width: 250px;
}
.th-action-250 {
    width: 267px;
}
.td-action-sm {
    width: 50px;
}
.th-action-sm {
    width: 50px;
}
.td, .th {
    &.td-hover {
        &:hover {
            background-color:rgba(0, 0, 0, 0.05);
        }
    }
    
}
.table.bordered {
    .th, .td {
        border: 0.0625rem solid #dee2e6;
    }
}

.tr.selected {
    background-color:$primary;
    color: #ffffff;
    &:hover {
        background-color:$primary !important;
        color: #ffffff !important;
    }
}

.table tr:last-child td, .table .tr:last-child .td {
    border-bottom: 0.0625rem solid #dee2e6;
}
.table tr:first-child td, .table .tr:first-child .td {
    border-top: none;
}

.dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    border-width: .2rem;
    border-radius: .1rem;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border .3s ease-in-out;

    &.active {
        border-color: #2196f3;
        background-color: #ffffff;
    };
      
    &.accept {
        border-color: #00e676;
        background-color: #ffffff;
    };
      
    &.reject {
        border-color: #ff1744;
    }
    p {
        margin-top: 0;
        margin-bottom: 0;
    }
}
.dropzone-list {
    li {
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.5;
        color: #8898aa;
        cursor: pointer;
        &:hover {
            color: #2196f3;
            text-decoration: underline;
        }
    }
}

.matrix-accordion {
    border: 0.0625rem solid #dee2e6;
    
    border-bottom: none;

    .list {
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        padding-bottom: 1rem;
        overflow-y: scroll;
        max-height: 14.8rem;
        padding-left: 1rem;
        .list-item {
            padding: 1rem;
            margin-right: 1rem;
            margin-top: 1rem;
            border: 0.0625rem solid #dee2e6;
            cursor: pointer;
        }
    }
}

.horizontal-menu {
    .menu-wrapper {
        .menu-wrapper--inner {
            //transform: none !important;
            .menu-item-wrapper {
                &:last-child {
                    margin-right: 1rem;
                }
                &:first-child {
                    margin-left: 1rem;
                }
                &:focus{
                    outline: none;
                }
                &.active {
                    .menu-item {
                        border: 0.0625rem solid $default;
                        background-color: $default;
                        color: #ffffff;
                    }
                }
                .menu-item {
                    border: 0.0625rem solid #cad1d7;
                    padding: .635rem 1rem; 
                    margin: .25rem .25rem;
                    cursor: pointer;
                    color: #8898aa;
                    transition: all 300ms ease-in-out;
                    border-radius: 0.25rem;
                    font-size: 14px;
                    &:hover {
                        background-color: #cad1d7;
                        color: #ffffff;
                        border: 0.0625rem solid #cad1d7;
                    }
                    &.has-date {
                        background-color: $green;
                        color: #ffffff;
                    }
                }
            }
        }
    }
    .scroll-menu-arrow {
        &:last-child {
            margin-left: 1rem;
        }
        &:first-child {
            margin-right: 1rem;
        }
        // .arrow-prev{
        //     margin-right: .5rem;
        // }
        // .arrow-next {
        //     margin-left: .5rem;
        // }
    }
}

.modal-fullwidth {
    .modal-xl {
        max-width: 100%;
    }
}



.input-group .rdt .rdtPicker {
    transition: all 150ms linear;
    margin-top: -20px;
    visibility: hidden;
    display: none;
    opacity: 0;
}

.input-group .rdt.rdtOpen .rdtPicker {
    opacity: 1;
    visibility: visible;
    margin-top: 0;
    display: block;
}

div.rdw-editor-main {
        border: 1px solid #F1F1F1;
        padding: 0px 10px 0px 10px;
}

th.title-pre-wrap, .th.title-pre-wrap {
    white-space: pre-wrap;
}

.list-group-item {
    font-size: 0.875rem;
    blockquote p {
        font-size: 0.875rem;
    }
}

.table td, .table .td, .table th, .table .th {
    padding: .4rem;
    vertical-align: middle;
}

.table.align-cells-top td {
    vertical-align: top;
}

.btn-xsm, .btn-group-xsm > .btn {
    &.btn-icon {
        width: 24px;
    }
    padding: 0;
    font-size: 0.7rem;
    line-height: 1.5;
    border-radius: 0.2rem;
    
    height: 24px;
    i {
        padding: 0 !important;
    }
}

.progress-percentage span {
    display: inline-block;
    color: #525f7f;
    font-size: .75rem;
    font-weight: normal;
}

.step-progress-label {
    font-size: .75rem;
}

.table.flex .td {
    display: flex;
    align-items: center;
}

.table.flex{
    &.scrollable {
        .th:last-child {
            box-sizing: content-box !important
        }
    }
    .td {
        display: flex;
        align-items: center;
    }
}

.has-error.form-group {
    .input-group .input-group-prepend {
        & + .rdt .form-control, .input-group-text {
            border-color: #ff1744;
        }

    }
}

$white-space-otions: pre, break-spaces, nowrap, pre-line, pre-wrap, none;
@each $option in $white-space-otions {
    .white-space-#{$option} {
        white-space: $option;
    }
}

.header-center {
    div.th {
        white-space: pre-wrap;
    }
}

// Material UI
span.MuiTypography-body2 {
    font-size: 0.7rem;
}

svg.MuiStepIcon-root.MuiStepIcon-completed {
    color: darkgreen;
}

svg.MuiStepIcon-root.MuiStepIcon-active {
    color: lightseagreen;
}
.entity-exists {
    background-color: lighten($info, 47.5%);
}

.k-animation-container{
    z-index: 10003
}

.k-multiselect {
    font-family: Roboto, "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    .k-input {
        display: block;
        width: 100%;
        height: calc(1.5em + 1.25rem);
        padding: 0.625rem 0.75rem;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.5;
        color: #8898aa;
        background-color: #fff;
        background-clip: padding-box;
        //border: 1px solid #cad1d7;
        border-radius: 0.25rem;
        box-shadow: none;
        transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
        position: relative;
        flex: 1 1 auto;
        width: 1%;
        margin-bottom: 0;
        opacity: .75;
        font-weight: 400;
    }
    .k-multiselect-wrap{
        display: flex;
    }
    &.k-state-focused {
        .k-multiselect-wrap{
            outline: none;
            box-shadow: none;
            border-color: rgba(50, 151, 211, 0.25);
        }
        outline: none;
    }
    ul.k-reset {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        li.k-button {
            background-color: #fff;
            padding: .15rem .75rem .15rem .85rem;
            white-space: nowrap;
            transition: all .3s ease-in-out;
            border: 1px solid #eee;
            border-radius: 15px;
            font-weight: 400;
            line-height: 1.5;
            color: #8898aa;
            font-size: .875rem;
            margin: .25rem;
            margin-left: .5rem;
            &:last-child {
                margin-bottom: .25rem;
            }
            
        }
    }
}
ul.k-list {
    font-family: Roboto, "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;

    li.k-item {
        font-weight: 400;
        line-height: 1.5;
        color: #8898aa;
        font-size: .875rem;
        padding: .8rem 1.1rem;
        line-height: 1em;
        &.k-state-selected, &.k-state-selected:hover {
            background: rgba(0,0,0,.1);
            color: #8898aa;
            &.k-state-focused {
                box-shadow: none;
                outline: none;
            }
        }
        &:hover {
            background: rgba(0,0,0,.1);
            color: #000;
        }
        &.k-state-focused {
            box-shadow: none;
            outline: none;
        }
    }
}

.sweet-alert.contract-dialog {
    width: 42em !important;
    h2 {
        font-size: 1.25rem !important;
        text-align: left !important;
        color: $gray-800;
        font-weight: 400 !important;
    }
    .dialog-wrapper {
        border: 1px solid #ddd;
        margin: .5rem -1.3rem 0;
        border-left: none;
        border-right: none;
        padding: 1rem 0;
        .dialog-section {
            h5 {
                font-size: 1.1rem !important;
                text-align: left;                
                margin-bottom: 0;
                &::before {
                    display: inline-block;
                    content: "";
                    border-top: .025rem solid #777;
                    width: 1rem;
                    margin: 0 0.5rem;
                    transform: translateY(-0.3rem);
                }
            }
            .dialog-form {
                padding: 0.5rem 2rem 0;
                text-align: left !important;
                .btn-xl {
                    padding: .1rem 1rem;
                    font-size: 2.5rem;
                    margin: .5rem 1rem 1rem 0;
                    border-color: #dddddd;
                }
                .dropzone {
                    margin-bottom: 1rem;
                    padding: 1.5rem 3rem;
                }
            }
        }
    }
}

.var-container {
    padding-left: 3rem;
    p {
        margin-bottom: .25rem;
        clear: both;
        float: left;
        cursor: pointer;
        &:hover {
            color: $default;
        }
    }
}

.sweet-alert.dispatch-confirm-dialog {
    width: 42em !important;
    .dialog-wrapper {
        border: 1px solid #ddd;
        margin: .5rem -1.3rem 0;
        border-left: none;
        border-right: none;
        padding: 1rem 0;
        .dialog-section {
            h5 {
                font-size: 1.1rem !important;
                text-align: left;                
                margin-bottom: 0;
                &::before {
                    display: inline-block;
                    content: "";
                    border-top: .025rem solid #777;
                    width: 1rem;
                    margin: 0 0.5rem;
                    transform: translateY(-0.3rem);
                }
            }
            .dialog-form {
                padding: 0.5rem 2rem 0;
                text-align: left !important;
                .btn-xl {
                    padding: .1rem 1rem;
                    font-size: 2.5rem;
                    margin: .5rem 1rem 1rem 0;
                    border-color: #dddddd;
                }
                .dropzone {
                    margin-bottom: 1rem;
                    padding: 1.5rem 3rem;
                }
            }
        }
    }
}